import request from '@/utils/request'

/**
 * @param {Object} data
 */

// 新闻列表
export function journalism_list_api(data) {
    return request({
        url: '/journalism/list',
        method: 'post',
        data
    })
}

// 新闻分类
export function journalism_type_api() {
    return request({
        url: '/journalism/type',
        method: 'post'
    })
}

// 新闻详情
export function journalism_details_api(data) {
    return request({
        url: '/journalism/details',
        method: 'post',
        data
    })
}


// 问答类型
export function news_type_list_api(data) {
    return request({
        url: '/news/type_list',
        method: 'post',
        data
    })
}

// 教学类型
export function news_teach_list(data) {
    return request({
        url: '/news/teach_list',
        method: 'post',
        data
    })
}